import gql from 'graphql-tag';
import { queries as zoneQueries } from '../sections/zones/queries';

export
const manifestDefaultFieldsFragment = `
  id,
  name,
  optimization_status,
  optimization_error_info,
  date,
  calculated,
  recalculation_required,
  couriers_count,
  points_count,
  missed_points_count,
  warning_points_count
`;

export
const manifestOptimizationFields = `
  ${manifestDefaultFieldsFragment}
  has_visited_points,
  task_type,
  optimization,
  clusterization,
  crow_matrices,
  runs,
  maxiter,
  distribution,
  balance_agents,
  balance_by_weight,
  by_agent,
  by_zone,
  min_weight,
  n_neighbors,
  improve_clusters,
  dont_make_routes,
  delivery_tolerance,
  speed_coeff,
  traffic_jams,
  strict_mode,
  skip_routes,
  preserve_couriers,
  total_distance,
  total_cost,
  total_weight,
  total_time,
  total_points,
  calc_time,
  n_days,
  by_group,
  auto_zones,
  open_routes,
  skip_schedules_transition,
  balance_by_time,
`;

export const queries = {

  FIRST_MANIFEST_QUERY: gql`
    query MANIFEST_LIST ($limit: Int $offset: Int $title_filter: String) {
      result(limit: $limit offset: $offset title_filter: $title_filter) {
        ${manifestDefaultFieldsFragment}
      }
    }
  `,

  UPDATE_MANIFEST: gql`
    mutation UPDATE_MANIFEST($id: Int!, $name: String!) {
      result(id: $id, name: $name) {
        ${manifestOptimizationFields}
      }
  }`,

  COPY_MANIFEST: gql`
    mutation COPY_MANIFEST(
      $manifest_id: Int!,
      $copy_settings: Boolean!,
      $copy_special_points: Boolean!,
      $copy_regular_points: Boolean!,
      $copy_couriers: Boolean!,
      $copy_zones: Boolean!,
      $data: String!) {
      result(
        manifest_id: $manifest_id
        copy_settings: $copy_settings
        copy_special_points: $copy_special_points
        copy_regular_points: $copy_regular_points
        copy_couriers: $copy_couriers
        copy_zones: $copy_zones
        data: $data
      ) {
        ${manifestOptimizationFields}
      }
    }`,

  QUERY_MANIFEST: gql`
    query MANIFEST ($id: Int!) {
      result(id: $id) {
        ${manifestOptimizationFields}
      }
  }`,

  OPTIMIZATION_START: gql`
    query OPTIMIZATION_START ($manifest_id: Int!) {
      result(manifest_id: $manifest_id) {
        ${manifestOptimizationFields}
      }
  }`,

  OPTIMIZATION_CANCEL: gql`
    query OPTIMIZATION_CANCEL ($manifest_id: Int!) {
      result(manifest_id: $manifest_id crutch: "optimization_cancel") {
        id
        optimization_status
        optimization_error_info
        calculated
      }
  }`,

  OPTIMIZATION_STATUS: gql`
  query OPTIMIZATION_STATUS ($manifest_id: Int!) {
    result(manifest_id: $manifest_id crutch: "optimization_status") {
      id
      optimization_status
      optimization_error_info
      calculated
    }
  }`,

  ZONES_LIST: zoneQueries.LIST,

  POINTS_COORDS_LIST: gql`
  query MANIFEST_POINTS_COORDS ($limit: Int $offset: Int $manifest_id: Int $title_filter: String) {
    result(limit: $limit offset: $offset manifest_id: $manifest_id title_filter: $title_filter)
    @connection(key: "points", filter: ["manifest_id"]) {
      id
      lat_lon
      garage
      depot
      weight
      volume
      backhaul_weight
      backhaul_volume
      courier_id
      pickup_point
      shipping_time
      summa
    }
  }`,

  POINTS_WAYBILL_LIST: gql`
  query MANIFEST_WAYBILL_POINTS ($limit: Int $offset: Int $manifest_id: Int) {
    result(limit: $limit offset: $offset manifest_id: $manifest_id)
    @connection(key: "waybill_points", filter: ["manifest_id", "offset", "limit"]) {
      id
      point_id
      geometry
      courier_id
      courier {
        id
        name
      }
      photos
      arrival_time
      departure_time
      departure_load
      departure_volume
      departure_backhaul_load
      departure_backhaul_volume
      departure_direct_load
      departure_direct_volume
      loaded_weight
      loaded_volume
      route_code
      error_code
      total_distance
      total_cost
      total_weight
      total_time
      points_served
      status
      last_status_change
      driver_comment
    }
  }`,

  POINTS_WAYBILL_MISSED_LIST: gql`
  query MANIFEST_WAYBILL_MISSED_POINTS ($manifest_id: Int) {
    result(manifest_id: $manifest_id)
    @connection(key: "waybill_points", filter: ["manifest_id"]) {
      id
      point_id
      courier_id
      driver_comment
      last_status_change
      route_code
      error_code
      status
    }
  }`,

  CLEAR_OPTIMIZATION_QUERY: gql`
  mutation OPTIMIZATION_CLEAR ($manifest_id: Int!) {
    result(manifest_id: $manifest_id crutch: "optimization_clear") {
      ${manifestDefaultFieldsFragment}
    }
  }
  `,

  COURIERS_TRACKS: gql`
  query MANIFEST_COURIERS_TRACKS ($manifest_id: Int) {
    result(manifest_id: $manifest_id crutch: "tracks") {
      id
      track
    }
  }`,
};
